/*
 Off Canvas Nav Module
 */
var offCanvasNav = {

    init: function() {

        // Setup nav config
        offCanvasNav.config = {
            btnClass: $('.toggle-nav'),
            isOpen: false,
            windowWidth: $(window).width()
        };

        // Initialise the nav
        offCanvasNav.initNav();

        // Width resize event
        $(window).on("resize", function(){
            if( $(this).width() !== offCanvasNav.config.windowWidth ){
                offCanvasNav.config.width = $(this).width();
                offCanvasNav.widthResizes();
            }
        });

    },

    initNav: function() {

        offCanvasNav.timeline = new TimelineLite({ paused: true });

        offCanvasNav.timeline
            .set( '.nav-wrapper', { display: 'block' })
            .set( 'body', { className: '+=nav-open' })
            .to( '.nav-wrapper', 0.4, { opacity: 1 })
            .staggerFrom( '.nav-wrapper ul li', 0.6, { left: '-25px', opacity: '0' }, 0.2 );

        offCanvasNav.config.btnClass.on( "click", offCanvasNav.toggleMenu);

    },

    widthResizes: function() {

        // If nav is open when the width is resized then close it down
        if(offCanvasNav.config.isOpen){
            offCanvasNav.toggleMenu();
        }

    },

    toggleMenu: function() {

        if(!offCanvasNav.config.isOpen){
            offCanvasNav.timeline.timeScale( 1 ).play();
        } else {
            offCanvasNav.timeline.timeScale( 2 ).reverse();
        }

        offCanvasNav.config.isOpen = !offCanvasNav.config.isOpen;

    }

};

$( document ).ready( offCanvasNav.init );