/*
 * Local Area Map Module
 */
var mapObject = {

    init: function(selector, lat, lng, zoomLevel) {

        mapObject.config = {
            width: $(window).width(),
            selector: selector,
            latitude: lat,
            longitude: lng,
            zoomLevel: zoomLevel,
            toggleClass: $('.toggle-filter'),
            filterIsOpen: false
        };

        mapObject.setDeviceType();

        // Width resize event
        $(window).resize(function(){
            if( $(this).width() !== mapObject.config.width ){
                mapObject.config.width = $(this).width();
                mapObject.widthResizes();
            }
        });

        mapObject.styles = [{
            "featureType": "landscape",
            "stylers": [
                {
                    "hue": "#FFBB00"
                },
                {
                    "saturation": 43.400000000000006
                },
                {
                    "lightness": 37.599999999999994
                },
                {
                    "gamma": 1
                }
            ]
        }, {
            "featureType": "road.highway",
            "stylers": [
                {
                    "hue": "#FFC200"
                },
                {
                    "saturation": -61.8
                },
                {
                    "lightness": 45.599999999999994
                },
                {
                    "gamma": 1
                }
            ]
        }, {
            "featureType": "road.arterial",
            "stylers": [
                {
                    "hue": "#FF0300"
                },
                {
                    "saturation": -100
                },
                {
                    "lightness": 51.19999999999999
                },
                {
                    "gamma": 1
                }
            ]
        }, {
            "featureType": "road.local",
            "stylers": [
                {
                    "hue": "#FF0300"
                },
                {
                    "saturation": -100
                },
                {
                    "lightness": 52
                },
                {
                    "gamma": 1
                }
            ]
        }, {
            "featureType": "water",
            "stylers": [
                {
                    "hue": "#0078FF"
                },
                {
                    "saturation": -13.200000000000003
                },
                {
                    "lightness": 2.4000000000000057
                },
                {
                    "gamma": 1
                }
            ]
        }, {
            "featureType": "poi",
            "stylers": [
                {
                    "hue": "#00FF6A"
                },
                {
                    "saturation": -1.0989010989011234
                },
                {
                    "lightness": 11.200000000000017
                },
                {
                    "gamma": 1
                }
            ]
        }, {
            "featureType": "poi.business",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }, {
            "featureType": "transit.station.rail",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }

        ];

        mapObject.options = {
            center: new google.maps.LatLng(mapObject.config.latitude, mapObject.config.longitude),
            scrollwheel: false,
            zoom: mapObject.config.zoomLevel,
            minZoom: 10,
            /* Disabling default UI widgets */
            disableDefaultUI: true,
            mapTypeId: 'Styled',
            draggable: true
        };

        mapObject.map = new google.maps.Map(document.getElementById(mapObject.config.selector), mapObject.options);


        // Constructor for custom zoom controls
        function ZoomControl(controlDiv, map) {
          
            // Creating divs & styles for custom zoom control
            controlDiv.style.padding = '5px';

            // Set CSS for the control wrapper
            var controlWrapper = document.createElement('div');
            controlWrapper.className = 'control-wrapper';
            controlDiv.appendChild(controlWrapper);

            // Set CSS for the zoomOut
            var zoomOutButton = document.createElement('div');
            zoomOutButton.className = 'zoom-out';
            controlWrapper.appendChild(zoomOutButton);

            // Set CSS for the zoomIn
            var zoomInButton = document.createElement('div');
            zoomInButton.className = 'zoom-in';
            controlWrapper.appendChild(zoomInButton);

            // Setup the click event listener - zoomIn
            google.maps.event.addDomListener(zoomInButton, 'click', function() {
                map.setZoom(map.getZoom() + 1);
            });

            // Setup the click event listener - zoomOut
            google.maps.event.addDomListener(zoomOutButton, 'click', function() {
                map.setZoom(map.getZoom() - 1);
            });  
            
        }

        // Create the DIV to hold the control and call the ZoomControl() constructor
        // passing in this DIV.
        var zoomControlDiv = document.createElement('div');
        var zoomControl = new ZoomControl(zoomControlDiv, mapObject.map);

        zoomControlDiv.index = 1;
        mapObject.map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(zoomControlDiv);


        mapObject.styledMapType = new google.maps.StyledMapType(mapObject.styles, { name: 'Styled' });
        mapObject.map.mapTypes.set('Styled', mapObject.styledMapType);

        var cvcTitle,
            cvcAddress,
            cvcContent;

        // Setup content for CVC marker icon
        if( mapObject.config.selector === 'contact_page_map' ) {
            cvcTitle = '<strong class="info-box-name">City Village Colection</strong>';
            cvcAddress = '<p class="info-box-address">90a tooley street<br> London<br> SE1 2TH</p>';
        } else {
            cvcTitle = '<strong class="info-box-name">City Village Colection</strong>';
            cvcAddress = '<p class="info-box-address">Location Address</p>';
        }

        cvcContent = cvcTitle + cvcAddress;

        var cvcIcon = new google.maps.MarkerImage("assets/img/map_pin.png", null, null, null, new google.maps.Size(60, 64));

        var cvcMarker = new google.maps.Marker({
            position: new google.maps.LatLng(mapObject.config.latitude, mapObject.config.longitude),
            map: mapObject.map,
            zIndex: 20,
            icon: cvcIcon
        });

        var cvcOptions = {
            content: cvcContent,
            closeBoxURL: 'assets/img/icon-close-info-window.svg',
            boxClass: 'infoBox info-box-cvc',
            disableAutoPan: false,
            maxWidth: 0,
            pixelOffset: new google.maps.Size(-95, -230),
            infoBoxClearance: new google.maps.Size(1, 1),
            isHidden: false,
            pane: "floatPane",
            enableEventPropagation: true,
            zIndex: 10
        };

        google.maps.event.addListener(cvcMarker, 'click', function() {
            $('.infoBox').remove();
            var cvcInfoBox = new InfoBox(cvcOptions);
            var markerPosition = cvcMarker.getPosition();
            cvcInfoBox.open(mapObject.map, cvcMarker);

            mapObject.map.panTo(markerPosition);
        });


        if( mapObject.config.selector === 'local_area_map' ) {
            mapObject.setupMarkers();
        }

        // Event Handlers
        // --------------------------

        // Open infoBox links in new window/tab
        $('.infoBox a').on('click', function() {
            window.open($(this).attr('href'));
            return false;
        });

        // Map Filter Nav interaction
        mapObject.config.toggleClass.on('click', function(event) {
            mapObject.filtersToggler( $(this) );
            return false;
        });

        // Clear all markers functionality
        $('.clear-all').on('click', function() {
            mapObject.clearAllMarkers();
            return false;
        });


        // Get the filters overlay up and running!!
        mapObject.initFilterAnimation();


    },

    widthResizes: function() {
        mapObject.setDeviceType();

        // If nav is open when the width is resized then close it down
        // if(mapObject.config.filterIsOpen){
            mapObject.initFilterAnimation();
     

        var center = mapObject.map.getCenter();
        google.maps.event.trigger(mapObject.map, "resize");
        mapObject.map.setCenter(center);

    },

    setDeviceType: function() {

        if (Modernizr.mq('(min-width: 1025px)')){
            mapObject.config.deviceType = 'desktop';
        }  else if (Modernizr.mq('(min-width: 768px)')){
            mapObject.config.deviceType = 'tablet';
        } else {
            mapObject.config.deviceType = 'mobile';
        }

    },

    initFilterAnimation: function() {

        TweenLite.set('#filters-container', {clearProps:"all"});
        TweenLite.set('.animation-element', {clearProps:"all"});
        TweenLite.set('body', { className: '-=filters-open' });

        mapObject.filtersOverlayTimeline = new TimelineLite({ paused: true });

        if( mapObject.config.deviceType === 'mobile' ) {

            mapObject.filtersOverlayTimeline
                .set( 'body', { className: '+=filters-open' })
                .from( '#filters-container', 0.8, { height: 0 })
                .staggerFrom( '.animation-element', 0.4, { top: '-10px', opacity: '0' }, 0.1 );

        }
            
        $('.open-filter-overlay').on('click touchstart', mapObject.toggleFilterAnimation );

    },

    toggleFilterAnimation: function() {
        
        if( !mapObject.config.filterIsOpen ) {
            mapObject.filtersOverlayTimeline.timeScale( 1 ).play();
        } else {
            mapObject.filtersOverlayTimeline.timeScale( 2 ).reverse();
        }

        mapObject.config.filterIsOpen = !mapObject.config.filterIsOpen;

        return false;

    },

    setupMarkers: function() {

        mapObject.markers = [];

        // Get JSON data
        $.getJSON('./data/map.json', function(data) {

            var hotspots = data.scenes[0].hotspots;

            $.each(hotspots, function(i) {

                var lat = hotspots[i].latitude;
                var lon = hotspots[i].longitude;
                var point = new google.maps.LatLng(lat, lon);
                var catName = hotspots[i].catName;
                var catNiceName = hotspots[i].catNiceName;
                var name = hotspots[i].name;
                var address = hotspots[i].address;
                var weburl = hotspots[i].weburl;

                // Wrap data in markup for infoboxes
                if (catNiceName !== undefined) {
                    catNiceName = '<h3 class="info-box-cat-name">' + catNiceName + '</h3>';
                } else {
                    catNiceName = '';
                }

                if (name !== undefined) {
                    name = '<strong class="info-box-name">' + name + '</strong>';
                } else {
                    name = '';
                }

                if (address !== undefined) {
                    address = '<p class="info-box-address">' + address + '</p>';
                } else {
                    address = '';
                }

                if (weburl !== undefined) {
                    weburl = weburl.replace('http://', '');
                    weburl = '<a href="http://' + weburl + '" target="_blank">Go to Site</a>';
                } else {
                    weburl = '';
                }

                var catIconUrl = 'assets/img/icon-' + catName + '.png';

                var catIcon = new google.maps.MarkerImage(catIconUrl, null, null, null, new google.maps.Size(24,24));

                // Add each marker
                var marker = new google.maps.Marker({
                    position: point,
                    anchor: new google.maps.Point(23, -23),
                    map: mapObject.map,
                    visible: false,
                    icon: catIcon
                });

                marker.category = catName;

                mapObject.markers.push(marker);

                var infoContent =
                    catNiceName +
                    name +
                    address +
                    weburl;

                var myOptions = {
                    content: infoContent,
                    closeBoxURL: 'assets/img/icon-close-info-window.svg',
                    closeBoxMargin: 0,
                    disableAutoPan: false,
                    maxWidth: 0,
                    alignBottom: true,
                    pixelOffset: new google.maps.Size(-95, -38),
                    isHidden: false,
                    pane: "floatPane",
                    enableEventPropagation: true,
                    zIndex: 10
                };

                // Add click event listener for markers
                google.maps.event.addListener(marker, 'click', function() {

                    // Remove other infoboxes
                    $('.infoBox').remove();

                    var infobox = new InfoBox(myOptions);
                    var markerPosition = marker.getPosition();

                    infobox.open(mapObject.map, marker);
                    mapObject.map.panTo(markerPosition);

                    // Bring marker to the top of the pile
                    marker.setZIndex(999);

                } );

                // Add mouseover event for markers
                google.maps.event.addListener(marker, 'mouseover', function() {

                    // Bring marker to the top of the pile
                    marker.setZIndex(999);

                });

            });

            // Show all on load
            mapObject.initMarkers();

        }).fail( function(data, textStatus, error) {
            console.error("getJSON failed, status: " + textStatus + ", error: " + error);
        });

    },

    initMarkers: function() {

        var initialMarkers = $('.toggle-filters li.active');

        // For each active category show the markers 
        for (var i = 0; i < initialMarkers.length; i++) {
            category = $(initialMarkers[i]).find('.toggle-filter').data('category');
            mapObject.showMarker(category);
        }

    },


    showMarker: function(category) {
        category = category.toString();
        for (var i = 0; i < mapObject.markers.length; i++) {
            if (mapObject.markers[i].category === category) {
                mapObject.markers[i].setVisible(true);
            }
        }
    },

    hideMarker: function(category) {
        category = category.toString();
        for (var i = 0; i < mapObject.markers.length; i++) {
            if (mapObject.markers[i].category === category) {
                mapObject.markers[i].setVisible(false);
            }
        }
    },
    clearAllMarkers: function() {
        mapObject.config.toggleClass.closest('li').removeClass('active');
        for (var i = 0; i < mapObject.markers.length; i++) {
            mapObject.markers[i].setVisible(false);
        }
    },

    filtersToggler: function(target) {

        var selectedCategory = target.data('category');

        $('.infoBox').remove();

        // Toggle filter
        if(target.closest('li').hasClass('active')) {
            mapObject.hideMarker(selectedCategory);
            target.closest('li').removeClass('active');
        } else {
            mapObject.showMarker(selectedCategory);
            target.closest('li').addClass('active');
        }

        mapObject.map.panTo(new google.maps.LatLng( mapObject.config.latitude, mapObject.config.longitude ));

    }

};