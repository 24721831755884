/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                console.log('O B L / Q U E');

                // Setup viewport for ScrollTo animations
                // ======================================
                var isSafari = navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0;
                var $viewport;

                if (isSafari) {
                    $viewport = $("body");
                } else {
                    $viewport = $("html, body");
                }

                var scrollOffset = $('.secondary-nav').outerHeight();

                // Animate scroll to on secondary nav links
                $(".scroll-to").on('click touchstart', function(event) {
                    event.preventDefault();
                    var section = $(this).attr('href');
                    $viewport.animate({
                        scrollTop: $(section).offset().top - scrollOffset
                    }, 2000, 'easeInOutExpo');

                    return false;
                });

                // Affix
                // ===========================
                $('.secondary-nav').affix({
                    offset: {
                        top: function() {
                            return $('#main').offset().top - scrollOffset - 1;
                        }
                    }
                });

                // Scroll to top of properties section on click
                $(".nav-tabs li").on('click touchstart', function(event) {

                    $viewport.animate({
                        scrollTop: $('#properties').offset().top - scrollOffset
                    }, 1200, 'easeInOutExpo');

                });

                // Boot up properties flexslider
                $('#properties .flexslider').flexslider({
                    animation: "fade",
                    useCSS: false,
                    directionNav: true,
                    controlNav: true,
                    prevText: "",
                    nextText: "",
                });

            }
        },
        'index': {
            init: function() {

                // Boot up intro flexslider
                // ======================================
                $('#landing').flexslider({
                    animation: "fade",
                    useCSS: false,
                    directionNav: true,
                    controlNav: false,
                    prevText: "",
                    nextText: "",
                });

                // Fire up the Local Area map
                mapObject.init('local_area_map', 51.514236, -0.193777, 15);

                // Animations
                // ===========================

                // Setup contoller
                arrowController = new ScrollMagic.Controller();

                // // Arrow 1
                // // ===================
                // var arrowAnimateScene1 = new ScrollMagic.Scene({
                //     triggerElement: '#landing .arrow-spacer',
                //     triggerHook: 0
                // })
                //     .setClassToggle('#landing .arrow-animated', "animate")
                //     .addTo(arrowController);

                // arrowAnimateScene1.addIndicators();


                // Arrow 2
                // ===================
                var arrowAnimateScene2 = new ScrollMagic.Scene({
                    triggerElement: '#sympathetic-development .arrow-spacer',
                    triggerHook: 0.35
                })
                    .setClassToggle('#sympathetic-development .arrow-animated', "animate")
                    .addTo(arrowController);

                // arrowAnimateScene2.addIndicators();


                // Arrow 3
                // ===================
                var arrowAnimateScene3 = new ScrollMagic.Scene({
                    triggerElement: '#properties .arrow-spacer',
                    triggerHook: 0.35
                })
                    .setClassToggle('#properties .arrow-animated', "animate")
                    .addTo(arrowController);

                // arrowAnimateScene3.addIndicators();


            }
        },
        'invest_in_cvc': {
            init: function() {

                // Animations
                // ===========================

                // Setup contoller
                arrowController = new ScrollMagic.Controller();


                // // Investment Opportunity Arrow 1
                // // ======================================
                var ioArrowAnimateScene1 = new ScrollMagic.Scene({
                    triggerElement: '#investment-opportunity .arrow-spacer',
                    triggerHook: 0.7
                })
                    .setClassToggle('#investment-opportunity .arrow-animated', "animate")
                    .addTo(arrowController);

                // ioArrowAnimateScene1.addIndicators();

                // Investment Opportunity Arrow 2
                // ======================================
                var ioArrowAnimateScene2 = new ScrollMagic.Scene({
                    triggerElement: '#strength-experience .arrow-spacer',
                    triggerHook: 0.7
                })
                    .setClassToggle('#strength-experience .arrow-animated', "animate")
                    .addTo(arrowController);

                // ioArrowAnimateScene2.addIndicators();

                // Investment Opportunity Arrow 3
                // ======================================
                var ioArrowAnimateScene3 = new ScrollMagic.Scene({
                    triggerElement: '#developments .arrow-spacer',
                    triggerHook: 0.7
                })
                    .setClassToggle('#developments .arrow-animated', "animate")
                    .addTo(arrowController);

                // ioArrowAnimateScene3.addIndicators();


                // Investment Opportunity Arrow 4
                // ======================================
                var ioArrowAnimateScene4 = new ScrollMagic.Scene({
                    triggerElement: '#experienced-team .arrow-spacer',
                    triggerHook: 0.7
                })
                    .setClassToggle('#experienced-team .arrow-animated', "animate")
                    .addTo(arrowController);

                // ioArrowAnimateScene4.addIndicators();

            }
        },
        'contact': {
            init: function() {

                mapObject.init('contact_page_map', 51.507139, -0.107584, 16);

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.